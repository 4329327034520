import { getRandomInt } from './fn';

export const advFn = {
  ready: true,
  bannersCounts: {},
  bannersUnregister: false,
  bannersRefreshTimeout: null,
  advtest_params : {
    IMAGE_PT:'',
    IMAGE:'/design/images/ad_test.jpeg',
    IMAGE_LS:'',
    CLICK_URL:'http://www.motor1.vsinko.local.com/'
  },
  bannersInit: function(selector) {
    /*const currentEdition = $(document.body).data('edtn');
    if (currentEdition == 'de') {
      this.stroerBannersInit(selector);
      return;
    }*/

    if (window.gadsId === false) {
      window.bannerContainerReference = '';
      if ('gtmDataLayer' in window) {
        var bannerConf = {
          event: 'bannersRender',
        };
        if (selector) {
          if (selector instanceof HTMLElement) {
            window.bannerContainerReference = selector;
          } else if (typeof selector == 'object') {
            window.bannerContainerReference = selector.length ? selector : '';
          }
        }
        gtmDataLayer.push(bannerConf);
      }
      return;
    }

    if (window.gadsId === false || !('googletag' in window)) return;

    var $ap = $('.ap').not('[data-ready="true"]');
    if (selector == 0) {
      $ap = $('.ap').empty();
    } else if (selector !== undefined) {
      $ap = $(selector)
        .find('.ap')
        .not('[data-ready="true"]');
    }

    var containers = [];
    var aptSlots = [];

    $ap.each(function() {
      var $self = $(this),
        data = $self.data(),
        dfpCode = data.dfpCode;

      var uolCode = data.uolCode;

      if (uolCode && 'uolads' in window && $self.css('display') !== 'none') {
        var uold = new Date(),
          oulId = uold.getTime() + getRandomInt(0, 999);
        var codeArr = uolCode.split('-');
        if (codeArr.length == 3) {
          codeArr.splice(2, 0, oulId);
          uolCode = codeArr.join('-');
          if (uolCode) {
            //console.log(uolCode);
            $self.html(
              $(
                '<div><script>window.uolads && window.uolads.push({ id: "' +
                  uolCode +
                  '" });</script></div>'
              ).attr('id', uolCode)
            );
            $self.attr('data-ready', true);
            return;
          }
        }
      }

      if (dfpCode && $self.css('display') !== 'none') {
        //console.log(dfpCode);

        var d = new Date(),
          id = 'apd_' + d.getTime() + '_' + getRandomInt(0, 999);

        var $div = $('<div></div>')
          .attr('id', id)
          .appendTo($self);

        var dfpAttrs = data.dfpAttrs;

        var dsize = [data.width, data.height];
        if ('sizes' in data) {
          var siz = [],
            item;
          var sizes = data.sizes.split(',');
          for (var i in sizes) {
            item = sizes[i].split('x');
            var sz2 = parseInt(item[1]);
            if (sz2) {
              siz.push([parseInt(item[0]), sz2]);
            } else {
              siz.push(sizes[i]);
            }
          }
          dsize = siz;
        }

        containers.push({
          id: id,
          dfpCode: dfpCode,
          dfpAttrs: data.dfpAttrs,
          dsize: dsize,
          width: data.width,
        });

        $self.attr('data-ready', true);
      }
    });

    if (containers.length) {
      googletag.cmd.push(function() {
        var slots = [];
        var dfpAttrs;

        for (var i = 0, c = containers.length; i < c; i++) {
          if (containers[i].width && typeof containers[i].width == 'number') {
            var slotName = '/' + gadsId + '/' + containers[i].dfpCode;
            slots[i] = googletag
              .defineSlot(slotName, containers[i].dsize, containers[i].id)
              .addService(googletag.pubads());
            if (window.apstag && containers[i].width > 1) {
              aptSlots.push({
                slotID: containers[i].id,
                slotName: slotName,
                sizes: containers[i].dsize,
              });
            }
          } else {
            slots[i] = googletag
              .defineOutOfPageSlot(
                '/' + gadsId + '/' + containers[i].dfpCode,
                containers[i].id
              )
              .addService(googletag.pubads());
          }
          dfpAttrs = containers[i].dfpAttrs;
          if (dfpAttrs.constructor === Object) {
            for (var g in dfpAttrs) {
              slots[i].setTargeting(
                g,
                typeof dfpAttrs[g] == 'number' ? dfpAttrs[g] + '' : dfpAttrs[g]
              );
            }
          }
        }
        if (typeof Storage !== 'undefined') {
          var targetingParamStr = localStorage.getItem('bcDFPTargetingParams');
          if (targetingParamStr) {
            var targetingParameters = JSON.parse(targetingParamStr);
            jQuery.each(targetingParameters, function(index, param) {
              window.googletag.pubads().setTargeting(param.key, param.value);
            });
          }
        }

        googletag.pubads().enableSingleRequest();

        googletag.enableServices();

        for (var j = 0, s = containers.length; j < s; j++) {
          googletag.display(containers[j].id);
        }

        if (aptSlots.length) {
          //console.log(aptSlots);
          apstag.fetchBids(
            {
              slots: aptSlots,
              timeout: 600,
            },
            function(bids) {
              googletag.cmd.push(function() {
                apstag.setDisplayBids();
              });
            }
          );
        }
      });
    }
  },
};
