import {lloadDisqus} from '../common/utils/fn';

// (function($) {
//   var dataEdt = $(document.body).data('edtn');
//   var itShowButton = document.getElementById('show-disqus');

//   var lloadDisqusEditions = ['us', 'it', 'de', 'fr', 'id', 'ru', 'ev_fr', 'ev_it', 'ev_ru', 'om', 'ra_us'];

//   var $container = $('#container_3rd_party_service').not('[data-ready="true"]'),
//     $comments = $('#disqus_thread'),
//     $comments_apb = $('.comments-container'),
//     init = false;

//   var top = function() {
//     var rect = $container.get(0).getBoundingClientRect();
//     var windowHeight =
//       window.innerHeight || document.documentElement.clientHeight;
//     var windowWidth = window.innerWidth || document.documentElement.clientWidth;

//     var vertInView = rect.top <= windowHeight && rect.top + rect.height >= 0;
//     var horInView = rect.left <= windowWidth && rect.left + rect.width >= 0;

//     return vertInView && horInView;
//   };

//   var append = function() {
//     init = true;

//     if (lloadDisqusEditions.includes(dataEdt)) {
//       itShowButton.addEventListener('click', function(e) {
//         e.preventDefault();
//         lazyLoadDisqus();
//         // itShowButton.data('visible', false);
//         itShowButton.dataset.visible = 'false';
//         itShowButton.remove();
//       });
//     } else {
//       lazyLoadDisqus();
//     }

//     function lazyLoadDisqus () {
//       var disqusWrapper = $('.disqus-wrapper');
//       if (disqusWrapper.length > 0) {
//         var $lazyBanner = $('.lazy-load-adv .apb .ap');

//         $lazyBanner.css('display', 'block');

//         banners('.lazy-load-adv');

//         var data = $comments.data(),
//           time = new Date(),
//           id = time.getTime();

//         var tpl_disqus = `<iframe id="disqus_comments_${id}"
//                     src="/disqus/?id=${id}&cid=${data.cid}&url=${data.url}&title=${data.title}"
//                     style="width:100%;height:100%"
//                     frameborder="0"
//                     scrolling="no"
//                     marginheight="0"
//                     marginwidth="0"
//                     allowtransparency="true"
//                     hspace="0"
//                     vspace="0"
//                     ></iframe>`;

//         $(tpl_disqus).appendTo(disqusWrapper);
//         $comments.attr('data-comments-ready', 1);
//         $comments_apb.removeClass('no-apb');
//         banners($comments_apb);
//       }
//     }
//   };

//   $(window).on('scroll load', function(event) {
//     var inViewPort = top();

//     setTimeout(function() {
//       if (inViewPort && !init) {
//         append();
//       }
//     }, 100);
//   });

//   $container.attr('data-ready', true);
// })(jQuery);

export function loadDisqusComments() {
  let edition = document.body.dataset.edtn;
  var outbrainEditions = ['us', 'ev_us', 'ev_de', 'ev_ar', 'br', 'de', 'es', 'ar', 'ev_br'];
  let commentShowButton = document.getElementById('show-disqus');

  let commentShowButtons = document.querySelectorAll('.show-disqus');
  let ifCommentsButton = commentShowButton instanceof Element ? true : false;
  let comments = document.getElementById('disqus_thread');
  const topComments = document.getElementById('m1-top-comments');

  let commentsContainer = document.querySelector(
    '#disqus-block-wrapper:not(.unobserve)',
  );

  if (ifCommentsButton) {
    commentShowButtons.forEach(commentButton => {
      commentButton.addEventListener('click', function(e) {
        e.preventDefault();
        lloadDisqus();
        commentShowButtons.forEach(el => {
          el.dataset.visible = 'false';
          el.style.display = 'none';
        });

        if (topComments) {
          topComments.remove();
        }

        if (outbrainEditions.includes(edition) && edition != 'ev_us' && edition != 'us') {
          comments.scrollIntoView({behavior: "smooth", block: "start"}); // inline parameter broke thewall notification =)
        }
      });
    })
  } else {
    let viewportHeight = window.innerHeight;
    let options = {
      rootMargin: `${viewportHeight}px`,
      threshold: 0,
    }
    
    let commentsObserver = new IntersectionObserver(function (entries) {
      entries.forEach(function (entry) {
        if (entry.isIntersecting) {
          let observerTarget = entry.target;
          observerTarget.classList.add('unobserve');
          lloadDisqus();
          commentsObserver.unobserve(observerTarget);
        }
      });
    }, options);

    commentsObserver.observe(commentsContainer);
  }
}
